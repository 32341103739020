<script setup>
import { onMounted, ref, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../assets/js/language'
import { Waterfall } from 'vue-waterfall-plugin-next'
import 'vue-waterfall-plugin-next/dist/style.css'

import AboutBanner from './components/about-banner'

import { getslList } from '@/api/new'

const store = useStore()
// 拿到vuex中的分类列表
const langtype = store.state.home.type
// eslint-disable-next-line no-undef
const langvalue = ref(returnLanguage(langtype))
// console.log(langvalue.value.top.one, '测试')

const checkedtype = ref(langvalue.value.top.six)
const type = ref(langtype)
const list = ref([])
const breakpoints = ref({
  1920: {
    rowPerView: 3
  }
})

const page = ref(1)
const limit = ref(100)
const productList = ref([])
const getNewList = () => {
  getslList().then(res => {
    let { data } = res
    productList.value = data
    console.log(data)
  })
}
getNewList()

/* 手动处理滚动 */
const handleScroll = () => {
  const scrollHeight = Math.max(
    document.documentElement.scrollHeight,
    document.body.scrollHeight
  )
  //滚动条滚动距离
  const scrollTop =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop
  //窗口可视范围高度
  const clientHeight =
    window.innerHeight ||
    Math.min(document.documentElement.clientHeight, document.body.clientHeight)
  if (clientHeight + scrollTop >= scrollHeight) {
    console.log('请求数据, 加载分页')
    // list.value.push({name: 2})
  }
  // if (clientHeight + scrollTop >= scrollHeight && page.value <= total.value) {
  //   //快到底时----加载
  //   page.value++
  //   console.log('请求数据')
  //   // getProduct()
  // }
}
onMounted(() => {
  // getProduct(tabModule.activeType.value, tabModule.activeClass.value)
  window.addEventListener('scroll', handleScroll)
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
watch(
  () => store.state.home.type,
  newVal => {
    langvalue.value = returnLanguage(newVal)
    checkedtype.value = returnLanguage(newVal).top.six
    type.value = newVal
    getNewList()
  },
  { immediate: true }
)
// return {
//   list,
//   breakpoints
// }
</script>

<template>
  <div class="a_detail">
    <AboutBanner :checkedtype="checkedtype" title="SUPERCOMPUTING SERVER" />
    <div class="center">
      <Waterfall
        :lazyload="false"
        :breakpoints="breakpoints"
        :gutter="20"
        :list="productList"
      >
        <template #item="{ item }">
          <div class="card_content">
            <div class="card_img">
              <img :src="'https://admin.wukongbc.com'+item.thumb" class="cover" />
            </div>
            <div class="content">
              <p class="title">{{ item.title }}</p>
              <div class="s_title">
                <p v-for="sitem, si in item.desc" :key="si">{{ sitem }}</p>
              </div>
            </div>
          </div>
        </template>
      </Waterfall>
    </div>
  </div>
</template>

<style scoped lang="less">
.a_detail {
  width: 100%;
  background: #f8f8f8;

  .center {
    box-sizing: border-box;
    width: 1540px;
    margin: 0 auto;
    padding: 150px 100px 103px;
    background: #fff;
    .card_content {
      border: 1px solid #bebebe;
      border-radius: 14px;
      margin-bottom: 20px;
      .card_img {
        height: 363px;
        border-radius: 14px;
        background: linear-gradient(
          116deg,
          rgba(193, 222, 232, 0.7) 9.86%,
          rgba(255, 255, 255, 0) 52.13%,
          rgba(251, 217, 185, 0.7) 97.99%
        );
        display: flex;
        align-items: center;
        justify-content: center;

        .cover {
          border-radius: 14px;
          width: 50%;
          height: 50%;
        }
      }

      .content {
        padding: 28px 29px 20px;
        .title {
          color: #000;
          height: 48px;
          font-family: Poppins;
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: 48px;
        }
        .s_title {
          color: #000;
          font-family: 'PingFang SC';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
          height: 180px;
        }
      }
    }
  }
}
</style>
